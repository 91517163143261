<template>
  <v-container fluid>
    <v-card tile>
      <div class="pl-6">
        <p class="text-body-1 font-weight-regular pt-4">
          Total gestiones listas para aprobación:
          {{ totalRegistros }}
        </p>
        <p class="text-caption text--secondary font-weight-bold mt-n3 pb-4" v-if="selectedFecha && selectedFecha[0]">
          <v-icon class="" color="black">mdi-calendar-range</v-icon>
          del {{ selectedFecha[0] }} al {{ selectedFecha[1] }}
        </p>
        <p class="text-caption text--secondary font-weight-bold mt-n3 pb-4" v-else>

        </p>
      </div>
    </v-card>

    <v-row>
      <!-- Recuadro para la tabla -->
      <v-col cols="10">
        <v-data-table disable-pagination :options.sync="options" :hide-default-footer="true" :headers="headers"
          :items="datosTabla" item-key="name" class="elevation-2" @click:row="handleClick">
          <template v-slot:[`item.resultado_evaluacion`]="{ item }">
            <v-chip v-if="item.resultado_evaluacion == 'rechazado'"
              :color="item.resultado_evaluacion == 'rechazado' ? 'red' : ''" dark>
              ROJO
            </v-chip>
            <v-chip v-if="item.resultado_evaluacion == 'revisión'"
              :color="item.resultado_evaluacion == 'revisión' ? 'yellow accent-2' : ''">
              AMARILLO
            </v-chip>
            <v-chip v-if="item.resultado_evaluacion == 'aprobado'"
              :color="item.resultado_evaluacion == 'aprobado' ? 'green' : ''" dark>
              VERDE
            </v-chip>
          </template>
          <!-- /**exportar registros a documento de excel */ -->
          <template v-slot:top>
            <v-row no-gutters>
              <v-spacer>
              </v-spacer>
              <v-btn x-small @click="exportToExcel" class="mt-3 ml-1 mr-1 flo" color="success">
                Exportar a Excel
              </v-btn>
            </v-row>
          </template>
          <!-- /**exportar registros a documento de excel, fin */ -->
          <template v-slot:[`item.deudor`]="{}"> deudor </template>
          <template v-slot:[`item.flag_tiene_pedido`]="{ item }">
            <span v-if="item.flag_tiene_pedido === 0">No</span>
            <span v-if="item.flag_tiene_pedido === 1">Si</span>
            <span v-if="item.flag_tiene_pedido === null">-</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ fnGetDateTime(item.createdAt) }}
          </template>
          <template v-slot:[`item.fecha_de_nacimiento`]="{ item }">
            {{ fnGetDateUTC(item.fecha_de_nacimiento) }}
          </template>
          <template v-slot:[`item.fecha_campania`]="{ item }">
            {{ fnGetDateUTC(item.fecha_campania) }}
          </template>
          <template v-slot:[`item.flag_precalificacion_listo_para_aprobacion_fecha`]="{ item }">
            {{ fnGetDateTime(item.flag_precalificacion_listo_para_aprobacion_fecha) }}
          </template>
          <template v-slot:[`item.flag_precalificacion_completada_fecha`]="{ item }">
            {{ fnGetDateTime(item.flag_precalificacion_completada_fecha) }}
          </template>
          <!-- verificamos si tiene un fiador -->
          <template v-slot:[`item.fiador_nombre_completo`]="{ item }">
            <span v-if="!item.fiador_nombre_completo">-</span>
            <span v-else>{{ item.fiador_nombre_completo }}</span>
          </template>

          <!-- para el botón de VER -->
          <template v-slot:[`item.ver`]="{ item }">
            <v-btn x-small dark color="#031D6A" @click="fnVerRegistro(item)">VER</v-btn>
          </template>
        </v-data-table>
        <!-- PAGINACION -->
        <v-col cols="12">
          <v-col offset="9" cols="3">
            <v-select v-model="pageSize" :items="pageSizes" label="Registros por página"
              @change="handlePageSizeChange"></v-select>
          </v-col>
          <v-col class="">
            Total de registros: {{ totalRegistros }}
            <v-pagination class="float-right" v-model="page" :length="totalPages" total-visible="15"
              next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange"></v-pagination>
          </v-col>
        </v-col>
        <!-- FIN PAGINACION -->
      </v-col>

      <!-- recuadro para los parámetros de búsqueda -->
      <v-col cols="2">
        <v-card tile>
          <div class="pt-2">
            <p class="text-body-2 font-weight-medium text--secondary pl-4">
              Parámetros de búsqueda
            </p>

            <v-card-text class="mt-n6">
              <v-text-field clearable v-model="id" label="ID / No. de gestión" prepend-icon="mdi-database-search"
                dense></v-text-field>

              <v-text-field v-model="nombre" label="Deudor" prepend-icon="mdi-database-search" class=""
                dense></v-text-field>

              <v-menu ref="fechaDeNacimientoDialog" v-model="modalFechaDeNacimiento" :close-on-content-click="false"
                :nudge-right="-17" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable v-model="SelectedFechasDeNacimiento" label="Fecha de nacimiento"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :selected-items-text="'$vuetify.datePicker.itemsSelected'" locale="es" width="240"
                  no-title scrollable v-model="dialogVarSelectedFechasDeNacimiento" range :max="disabledFecha"
                  color="primary" header-color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalFechaDeNacimiento = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary"
                    @click="$refs.fechaDeNacimientoDialog.save(dialogVarSelectedFechasDeNacimiento)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field clearable v-model="search_nit" label="NIT" prepend-icon="mdi-database-search"
                dense></v-text-field>

              <v-text-field v-model="documentoIdentificacion" label="Documento identificación"
                prepend-icon="mdi-database-search" dense></v-text-field>

              <v-select clearable v-model="searchTipoDeCliente" :items="itemssearchTipoDeCliente"
                label="Tipo de cliente" prepend-icon="mdi-database-search" dense></v-select>

              <v-text-field clearable v-model="nombre_fiador" label="Fiador" prepend-icon="mdi-database-search" class=""
                dense></v-text-field>

              <v-select clearable v-model="resultadoEvaluacion" :items="itemsResultadoEvaluacion"
                label="Resultado evaluación" prepend-icon="mdi-database-search" dense></v-select>

              <v-menu ref="fechaDialog" v-model="modalFechas" :close-on-content-click="false" :nudge-right="-17"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="selectedFecha" label="Fecha evaluación" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :selected-items-text="'$vuetify.datePicker.itemsSelected'" locale="es" width="240"
                  no-title scrollable v-model="dialogVarSelectedFecha" range :max="disabledFecha" color="primary"
                  header-color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalFechas = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.fechaDialog.save(dialogVarSelectedFecha)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu ref="fechaCompletacionDialog" v-model="modalFechaCompletacion" :close-on-content-click="false"
                :nudge-right="-17" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable v-model="SelectedCompletacionFechas" label="Fecha completación"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :selected-items-text="'$vuetify.datePicker.itemsSelected'" locale="es" width="240"
                  no-title scrollable v-model="dialogVarSelectedCompletacionFechas" range :max="disabledFecha"
                  color="primary" header-color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalFechaCompletacion = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary"
                    @click="$refs.fechaCompletacionDialog.save(dialogVarSelectedCompletacionFechas)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu ref="fechasListasParaAprobacionDialog" v-model="modalFechaListaParaAprobacion"
                :close-on-content-click="false" :nudge-right="-17" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable v-model="SelectedListaParaAprobacionFecha" label="Fecha lista para aprobación"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :selected-items-text="'$vuetify.datePicker.itemsSelected'" locale="es" width="240"
                  no-title scrollable v-model="dialogVarSelectedListaParaAprobacionFecha" range :max="disabledFecha"
                  color="primary" header-color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalFechaListaParaAprobacion = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary"
                    @click="$refs.fechasListasParaAprobacionDialog.save(dialogVarSelectedListaParaAprobacionFecha)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field clearable v-model="usuario_web" label="Usuario web" prepend-icon="mdi-database-search"
                dense></v-text-field>

              <v-menu ref="fechaCampaniasDialog" v-model="modalFechaCampania" :close-on-content-click="false"
                :nudge-right="-17" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable v-model="SelectedFechasCampania" label="Fecha campaña"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :selected-items-text="'$vuetify.datePicker.itemsSelected'" locale="es" width="240"
                  no-title scrollable v-model="dialogVarSelectedFechasCampania" range :max="disabledFecha"
                  color="primary" header-color="primary">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalFechaCampania = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.fechaCampaniasDialog.save(dialogVarSelectedFechasCampania)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-select clearable v-model="search_pedido" :items="itemsPedido" label="Con o sin pedido"
                prepend-icon="mdi-database-search" dense></v-select>

              <v-text-field clearable v-model="sector" label="Sector" prepend-icon="mdi-database-search"
                dense></v-text-field>



            </v-card-text>
          </div>
          <v-card-actions class="justify-end pb-6 mt-n4">
            <v-btn small color="#031D6A" class="white--text mr-1" @click="fnAplicarParametros()">
              APLICAR PARÁMETROS
              <v-icon small class="ml-2">mdi-magnify</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- para el recuadro que indica que Se aprobó exitosamente el crédito-->
    <v-snackbar v-model="snackbar" timeout="3500" color="success" right top class="mt-14">
      <v-row>
        <v-col cols="1" md="1">
          <v-icon>mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="11" md="11">
          <div class="text-center">
            <p class="text-subtitle-2 font-weight-medium white--text">
              Se aprobó exitosamente el crédito
            </p>
            <p class="text-subtitle-2 font-weight-medium white--text mt-n4 mb-n1">
              para la evaluación #{{ numeroEvaluacion }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from 'moment';
require('moment/locale/es.js');
import { mapActions } from 'vuex';
export default {
  name: 'gestionesListasParaAprobacion',
  data() {
    return {
      //paginacion
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
      totalRegistros: 0,
      //paginacion fin
      //variables para la info de la ventana verde superior derecha, cuando se finalizó una revisión exitosa
      snackbar: false,
      numeroEvaluacion: 33256,

      dialogIniciarProceso: false, //para mostrar la ventana de dialogo que contiene los detalles de la evaluacion
      totalGestionesEnRevision: 20, //variable temporal para mostrar el número total de precalificaciones completadas que se muestra en la card superior
      fechaSelect: '', // fecha de prueba que se muestra en la card superior

      //encabezados para la tabla
      headers: [
        {
          text: 'Número evaluación',
          align: 'start',
          value: 'id',
          sortable: true,
        },
        {
          text: 'Nombre persona',
          align: 'start',
          value: 'nombre_completo',
          sortable: false,
          width: 180,
        },
        {
          text: 'Fecha de nacimiento',
          align: 'start',
          value: 'fecha_de_nacimiento',
          sortable: false,
          width: 150,
          type: 'date'
        },
        {
          text: 'NIT',
          align: 'start',
          value: 'nit',
          sortable: false,
        },
        {
          text: 'Documento identificación',
          align: 'start',
          value: 'dpi',
          sortable: false,
        },
        {
          text: 'Perfil persona',
          align: 'start',
          value: 'deudor',
          sortable: false,
          type: 'deudor'
        },
        {
          text: 'Tipo de cliente',
          align: 'start',
          value: 'buro_tipo_de_usuario',
          sortable: false,
        },
        {
          text: 'Fiador',
          align: 'center',
          value: 'fiador_nombre_completo',
          sortable: false,
        },
        {
          text: 'Resultado evaluación',
          align: 'start',
          value: 'resultado_evaluacion',
          sortable: false,
        },

        {
          text: 'Fecha evaluación ',
          align: 'start',
          value: 'createdAt',
          sortable: false,
          type: 'datetime',
          width: 180

        },
        {
          text: 'Fecha completación',
          align: 'start',
          value: 'flag_precalificacion_completada_fecha',
          sortable: false,
          type: 'datetime',
          width: 180
        },
        {
          text: 'Fecha listo para aprobación',
          align: 'start',
          value: 'flag_precalificacion_listo_para_aprobacion_fecha',
          sortable: false,
          type: 'datetime',
          width: 180
        },
        {
          text: 'Nombre Usuario web',
          align: 'start',
          value: 'inicio_de_gestion_web',
          sortable: false,
          width: 150,
          type: 'string'
        },
        {
          text: 'Código Usuario Web',
          align: 'start',
          value: 'inicio_de_gestion_web_user_codigo',
          sortable: false,
          width: 150,
          type: 'string'
        },
        {
          text: 'Fecha campaña',
          align: 'start',
          value: 'fecha_campania',
          width: 150,
          sortable: false,
          type: 'date'
        },
        // {
        //   text: 'Código de usuario',
        //   align: 'start',
        //   value: 'CREADO_POR_USUARIO',
        //   sortable: false,
        //   type: 'string'
        // },
        // {
        //   text: 'Nombre de usuario',
        //   align: 'start',
        //   value: 'CREADO_POR_USUARIO_NOMBRE',
        //   sortable: false,
        //   width: 180,
        //   type: 'string'
        // },
        { text: 'Con o Sin pedido', align: 'start', value: 'flag_tiene_pedido', sortable: false, type: 'boolean' },
        { text: 'Sector', align: 'start', value: 'sector', sortable: false },
        { text: 'Ver', align: 'center', value: 'ver', sortable: false },
      ],

      //datos para mostrar en la tabla (se tiene un dato de prueba)
      datosTabla: [],

      //--------------variables para el cuadro de búsqueda

      //variables para la fecha
      modalFechas: false,
      dialogVarSelectedFecha: null,
      selectedFecha: [
        null,
        null
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas
      disabledFecha: moment().format('YYYY-MM-DD'),

      modalFechaCompletacion: false,
      dialogVarSelectedCompletacionFechas: null,
      SelectedCompletacionFechas: [
        null,
        null
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas

      modalFechaListaParaAprobacion: false,
      dialogVarSelectedListaParaAprobacionFecha: null,
      SelectedListaParaAprobacionFecha: [
        null,
        null
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas

      modalFechaCampania: false,
      dialogVarSelectedFechasCampania: null,
      SelectedFechasCampania: [
        null,
        null
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas

      modalFechaDeNacimiento: false,
      dialogVarSelectedFechasDeNacimiento: null,
      SelectedFechasDeNacimiento: [
        null,
        null
      ], //en esta variable es donde se almacenará la fecha o el rango de fechas

      nombre: null, //almacenamos el nombre para los parámetros de búsqueda
      nombre_fiador: null,
      search_nit: null,
      search_pedido: null,
      documentoIdentificacion: null,
      perfilPersona: null,
      resultadoEvaluacion: null,
      usuarios: null,
      usuario_web: null,
      sector: null,
      ruta: null,
      id: null,
      sap: null,
      searchTipoDeCliente: null,
      //Valores con valores temporales
      itemsPerfilPersona: ['Deudor', 'random', 'otro'],
      itemsResultadoEvaluacion: ['VERDE', 'ROJO', 'AMARILLO'],
      itemsPedido: ['Si', 'No'],
      itemssearchTipoDeCliente: ["nuevo", "reingreso"],
      itemsUsuarios: ['user1', 'user2', 'user3'],
      itemsSector: ['95', '56', '88'],
      itemsRuta: ['EMP095', 'SER765', 'RTG123'],

      detalleEvaluacion: {}, //para mostrar la info en la ventana de dialogo
    };
  },

  methods: {
    /**usar las filas de la columna, para hacer click en gestion */
    handleClick(value) {
      this.fnVerRegistro(value)
    },
    /**
     * *
     * exportar registros a documento de excel*/
    async exportToExcel() {
      try {
        let XLSX = require('xlsx');
        // Obtener todos los registros
        const allRecords = await this.getAllRecords();

        // Preparar los datos para el Excel
        const excelData = allRecords.map((record) => {
          let rowData = {};
          this.headers.forEach((header) => {
            if (header.text != 'Ver') {
              // Usar el 'text' como nombre de columna y 'value' para obtener el dato
              rowData[header.text] = this.formatCellValue(
                record[header.value],
                header.type
              );
            }
          });
          return rowData;
        });

        // Crear una hoja de trabajo
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Crear un libro de trabajo y agregar la hoja
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Registros');

        // Guardar el archivo
        XLSX.writeFile(wb, 'registros_exportados.xlsx');
      } catch (error) {
        console.error('Error al exportar a Excel:', error);
        // Aquí puedes agregar un manejo de errores más robusto, como mostrar un mensaje al usuario
      }
    },
    async getAllRecords() {
      const totalRecords = this.totalRegistros;
      const params = this.getParams();
      params['$limit'] = totalRecords;
      params['$skip'] = 0;

      try {
        const response = await this.findGestiones({ query: params });
        console.log('todas las gestiones: ', response);
        return response.data;
      } catch (error) {
        console.error('Error al obtener todos los registros:', error);
        throw error;
      }
    },
    formatCellValue(value, type) {
      if (type === 'deudor') {
        return 'deudor';
      }
      if (type === 'date') {
        return this.fnGetDate(value);
      }
      if (type === 'datetime') {
        return this.fnGetDateTime(value);
      }
      if (type === 'boolean') {
        if (value === 1) {
          return 'Si'
        }
        else if (value === 0) {
          return 'No'
        } else {
          return '-'
        }
      }
      // Manejar otros tipos según sea necesario
      return value;
    },
    /**
     * *
     * exportar registros a documento de excel, fin*/
    //
    fnGetDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : '';
    },
    fnGetDateTime(date) {
      return date ? moment(date).format('DD-MM-YYYY HH:mm:ss a') : '';
    },
    fnGetDateUTC(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : '';
    },
    //
    ...mapActions('Gestiones', {
      findGestiones: 'find',
      getGestiones: 'get',
      patchGestiones: 'patch',
    }),
    getParams() {
      const params = {};
      /**paginacion */
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;

      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? -1 : 1;
        sorting[this.options.sortBy[0].toString()] = flag;

        console.log('sorting', sorting);
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { id: -1 };
      }
      /**paginacion fin */


      params['flag_precalificacion_listo_para_aprobacion'] = 1;
      params['flag_precalificacion_concluidas'] = {
        $eq: null,
      };

      /** parametros de búsqueda */
      /**fechas */
      /** */
      if (this.selectedFecha != null) {
        if (this.selectedFecha[0] && this.selectedFecha[1]) {
          /**fechas */
          /** */
          let split1 = this.selectedFecha[0].split('-');
          let split2 = this.selectedFecha[1].split('-');
          let firstDay = new Date(split1[2], split1[1] - 1, split1[0], 0, 0, 0);
          let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);
          params['createdAt'] = {
            $gte: firstDay,
            $lte: lastDay,
          };
        }
      }
      if (this.SelectedCompletacionFechas != null) {
        if (this.SelectedCompletacionFechas[0] && this.SelectedCompletacionFechas[1]) {
          /**fechas */
          /** */
          let split1 = this.SelectedCompletacionFechas[0].split('-');
          let split2 = this.SelectedCompletacionFechas[1].split('-');
          let firstDay = new Date(split1[2], split1[1] - 1, split1[0], 0, 0, 0);
          let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);
          params['flag_precalificacion_completada_fecha'] = {
            $gte: firstDay,
            $lte: lastDay,
          };
        }
      }
      if (this.SelectedListaParaAprobacionFecha != null) {
        if (this.SelectedListaParaAprobacionFecha[0] && this.SelectedListaParaAprobacionFecha[1]) {
          /**fechas */
          /** */
          let split1 = this.SelectedListaParaAprobacionFecha[0].split('-');
          let split2 = this.SelectedListaParaAprobacionFecha[1].split('-');
          let firstDay = new Date(split1[2], split1[1] - 1, split1[0], 0, 0, 0);
          let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);
          params['flag_precalificacion_listo_para_aprobacion_fecha'] = {
            $gte: firstDay,
            $lte: lastDay,
          };
        }
      }

      if (this.SelectedFechasCampania != null) {
        if (this.SelectedFechasCampania[0] && this.SelectedFechasCampania[1]) {
          /**fechas */
          /** */
          //
          let split1 = this.SelectedFechasCampania[0].split('-');
          let split2 = this.SelectedFechasCampania[1].split('-');
          let firstDay = new Date(split1[2], split1[1] - 1, split1[0] - 2, 23, 59, 59);
          let lastDay = new Date(split2[2], split2[1] - 1, split2[0] - 1, 23, 59, 59);
          params['fecha_campania'] = {
            $gte: firstDay,
            $lte: lastDay,
          };
        }
      }

      if (this.SelectedFechasDeNacimiento != null) {
        if (this.SelectedFechasDeNacimiento[0] && this.SelectedFechasDeNacimiento[1]) {
          /**fechas */
          /** */
          let split1 = this.SelectedFechasDeNacimiento[0].split('-');
          let split2 = this.SelectedFechasDeNacimiento[1].split('-');
          let firstDay = new Date(split1[2], split1[1] - 1, split1[0] - 2, 23, 59, 59);
          let lastDay = new Date(split2[2], split2[1] - 1, split2[0] - 1, 23, 59, 59);
          params['fecha_de_nacimiento'] = {
            $gte: firstDay,
            $lte: lastDay,
          };
        }
      }

      /** */
      /**fechas */
      /** */
      if (this.id) {
        params['id'] = this.id;
      }
      if (this.nombre) {
        const nombres = this.nombre.split(' ').filter(Boolean); // Separa el término de búsqueda en palabras individuales
        const conditions = nombres.map(nombrePart => {
          return [
            { deudor_nombre_completo: { $like: `%${nombrePart}%` } },
            { primer_nombre: { $like: `%${nombrePart}%` } },
            { segundo_nombre: { $like: `%${nombrePart}%` } },
            { tercer_nombre: { $like: `%${nombrePart}%` } },
            { primer_apellido: { $like: `%${nombrePart}%` } },
            { segundo_apellido: { $like: `%${nombrePart}%` } },
            { tercer_apellido_o_casada: { $like: `%${nombrePart}%` } }
          ];
        });

        // Combina las condiciones de cada palabra en un solo array
        params['$and'] = conditions.map(condGroup => ({ $or: condGroup }));
      }

      if (this.nombre_fiador) {
        const nombresFiador = this.nombre_fiador.split(' ').filter(Boolean); // Divide el término de búsqueda en palabras individuales
        const conditionsFiador = nombresFiador.map(nombrePart => {
          return [
            { fiador_nombre_completo: { $like: `%${nombrePart}%` } },
            { fiador_primer_nombre: { $like: `%${nombrePart}%` } },
            { fiador_segundo_nombre: { $like: `%${nombrePart}%` } },
            { fiador_tercer_nombre: { $like: `%${nombrePart}%` } },
            { fiador_primer_apellido: { $like: `%${nombrePart}%` } },
            { fiador_segundo_apellido: { $like: `%${nombrePart}%` } },
            { fiador_tercer_apellido: { $like: `%${nombrePart}%` } }
          ];
        });

        // Combina las condiciones de cada palabra en un solo array
        params['$and'] = conditionsFiador.map(condGroup => ({ $or: condGroup }));
      }
      /** */
      if (this.documentoIdentificacion) {
        params['dpi'] = {
          $like: '%' + this.documentoIdentificacion + '%',
        };
      }
      /** */
      if (this.documentoIdentificacion) {
        params['dpi'] = {
          $like: '%' + this.documentoIdentificacion + '%',
        };
      }
      /** */
      if (this.resultadoEvaluacion) {
        if (this.resultadoEvaluacion == 'ROJO') {
          params['resultado_evaluacion'] = 'rechazado';
        }
        if (this.resultadoEvaluacion == 'AMARILLO') {
          params['resultado_evaluacion'] = 'revisión';
        }
        if (this.resultadoEvaluacion == 'VERDE') {
          params['resultado_evaluacion'] = 'aprobado';
        }
      }
      /** */
      if (this.sector) {
        params['sector'] = this.sector;
      }
      if (this.search_nit) {
        params['nit'] = this.search_nit;
      }
      /** */
      if (this.ruta) {
        params['ruta'] = this.ruta;
      }
      if (this.searchTipoDeCliente) {
        params['buro_tipo_de_usuario'] = this.searchTipoDeCliente;
      }
      if (this.id) {
        params['id'] = this.id
      }
      if (this.sap) {
        params['codigo_sap'] = this.sap
      }
      if (this.usuarios) {
        params['CREADO_POR_USUARIO'] = {
          $like: '%' + this.usuarios + '%',
        };
      }
      if (this.search_pedido) {
        if (this.search_pedido == 'Si') {
          params['flag_tiene_pedido'] = 1
        }

        if (this.search_pedido == 'No') {
          params['flag_tiene_pedido'] = 0
        }
      }
      if (this.usuario_web) {
        params['$or'] = [
          {
            inicio_de_gestion_web: {
              $like: '%' + this.usuario_web + '%',
            },
          },
          {
            inicio_de_gestion_web_user_codigo: {
              $like: '%' + this.usuario_web + '%',
            },
          }
        ];

        // Validar si this.usuario_web es numérico
        if (!isNaN(this.usuario_web)) {
          params['$or'].push({
            inicio_de_gestion_web_user_id: this.usuario_web
          });
        }
      }
      /** parametros de búsqueda, fin */

      return params;
    },
    main() {
      this.findGestiones({ query: this.getParams() }).then((r) => {
        for (let index = 0; index < r.data.length; index++) {
          /**dar formato al campo "fullName", desplieigue en tabla */
          const element = r.data[index];
          console.log('flag_precalificacion_listo_para_aprobacion_fecha', element.flag_precalificacion_listo_para_aprobacion_fecha);
          let fullName = [
            element.primer_nombre,
            element.segundo_nombre,
            element.tercer_nombre,
            element.primer_apellido,
            element.segundo_apellido,
            element.tercer_apellido_o_casada,
          ]
            .filter(Boolean)
            .join(' ');

          r.data[index].nombre_completo = fullName;
        }
        /**dar formato al campo "fullName", desplieigue en tabla */
        /** */
        this.datosTabla = r.data;
        this.total_precalificaciones = r.total;
        /** */
        this.totalRegistros = r.total;
        this.totalPages = Math.ceil(
          parseInt(r.total) / parseInt(this.pageSize)
        );
      });
    },
    /**paginacion */
    handlePageChange(value) {
      this.page = value;
      this.main();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.main();
    },
    /**paginacion fin */

    fnVerRegistro(data) {
      console.log(data);
      this.$router.push('/detalle-gestion/' + data.id + '/ParaAprobacion');
    },

    //funcion para recopilar toda la información cuando se da clic en el botón de aplicar parámetros (en la card de búsqueda)
    fnAplicarParametros() {
      console.log('aplicanco parámetros de búsqueda');
      this.page = 1;

      this.main();
    },

    //funcion para mostrar el recuadro verde la esquina superior derecha que indica que se finalizó la revisión
    fnFinalizacionRevision() {
      const revision = localStorage.getItem('evaluacion');
      if (revision) {
        console.log('se terminó una revisión');
        this.snackbar = true;

        this.datosTabla = []; //Se borran los datos de la tabla, como ejemplo nada mas
      } else {
        console.log('no se revisó nada');
      }

      localStorage.removeItem('evaluacion'); //eliminamos la info almacenada de revisión
    },
  },

  watch: {
    options: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        this.main();
      },
      deep: true,
      immediate: true,
    },
    /** parametrización por fechas con el calendario*/
    dialogVarSelectedFecha(newValue) {

      if (newValue != null) {
        this.selectedFecha = [
          moment(newValue[0]).format('DD-MM-YYYY'),
          moment(newValue[1]).format('DD-MM-YYYY'),
        ];
      } else {
        this.selectedFecha = [null, null
        ];
      }

    },
    dialogVarSelectedCompletacionFechas(newValue) {

      if (newValue != null) {
        this.SelectedCompletacionFechas = [
          moment(newValue[0]).format('DD-MM-YYYY'),
          moment(newValue[1]).format('DD-MM-YYYY'),
        ];
      } else {
        this.SelectedCompletacionFechas = [null, null
        ];
      }

    },
    dialogVarSelectedListaParaAprobacionFecha(newValue) {

      if (newValue != null) {
        this.SelectedListaParaAprobacionFecha = [
          moment(newValue[0]).format('DD-MM-YYYY'),
          moment(newValue[1]).format('DD-MM-YYYY'),
        ];
      } else {
        this.SelectedListaParaAprobacionFecha = [null, null
        ];
      }

    },

    dialogVarSelectedFechasCampania(newValue) {
      if (newValue != null) {
        this.SelectedFechasCampania = [
          moment(newValue[0]).format('DD-MM-YYYY'),
          moment(newValue[1]).format('DD-MM-YYYY'),
        ];
      } else {
        this.SelectedFechasCampania = [null, null
        ];
      }
    },

    dialogVarSelectedFechasDeNacimiento(newValue) {
      if (newValue != null) {
        this.SelectedFechasDeNacimiento = [
          moment(newValue[0]).format('DD-MM-YYYY'),
          moment(newValue[1]).format('DD-MM-YYYY'),
        ];
      } else {
        this.SelectedFechasDeNacimiento = [null, null
        ];
      }
    },
  },
  /** */
  mounted() {
    this.main();
  },

  //para verificar si regresamos de alguna revisión al ejecutar el componente (únicamente para pruebas)
  created() {
    this.fnFinalizacionRevision();
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 2px;
}
</style>
